.App {
    text-align: center;
}

.app-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
}

@media only screen and (max-width: 767px) {
    .app-content {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

.header-bottom-border {
    margin-top: 6px !important;
    background: #FF9B7A;
    border-radius: 3px;
    width: 40px;
    height: 6px;
}

.active-menu{
    color: #317499 !important;
    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    line-height: 22px !important;
}

.text-align-center{
    text-align: center !important;
}
.margin-top-20{
    margin-top: 20px !important;
}

.margin-top-30{
    margin-top: 30px !important;
}

.margin-bottom-15{
    margin-bottom: 15px !important;
}

.margin-bottom-10{
    margin-bottom: 10px !important;
}

.margin-top-40{
    margin-top: 40px !important;
}